/** @jsx jsx */

import { Router } from '@reach/router'
import { jsx } from 'theme-ui'

import Character from '../components/character'

const Characters = () => {
  return (
    <Router>
      <Character path="/characters:id" />
    </Router>
  )
}

export default Characters
